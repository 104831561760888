<template>
  <div />
</template>

<script>
import Auth from '@aws-amplify/auth'
import { logError } from '@/utils/logging'

import { mapActions } from 'vuex'

const errorMessage = "We've encountered an error while trying to update your email address." +
  ' Sign in to try updating your email address again.'

export default {
  name: 'VerifyEmail',
  async created() {
    this.updateLoading(true)
    await fetch(
      `${process.env.VUE_APP_UNAUTHENTICATED_ENDPOINT}/users/verify-email`,
      {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(this.$route.query.code),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        if (response.ok) {
          this.flash('Your email address has been updated. Please sign in with your new email address.')
        } else {
          this.flashError(errorMessage)
        }
      })
      .catch(e => {
        logError(e, 'VerifyEmail.js')
        this.flashError(errorMessage)
      })
      .finally(() => {
        Auth.signOut().finally(() => {
          this.updateLoading(false)
          this.$router.push({ name: 'login' })
        })
      })
  },
  methods: {
    ...mapActions(['updateLoading', 'flash', 'flashError'])
  }
}
</script>
